
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

const actions = {
  async login({ commit }, { email, password }) {
    try {
      commit('SET_AUTH_IS_LOADING', true)

      const auth = getAuth()
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      commit('SET_UID', user.uid)
      
      return user

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_AUTH_IS_LOADING', false)
    }
  },

  async logout({ commit }) {
    try { 
      const auth = getAuth()
      await signOut(auth)
      commit('SET_UID', null)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    }
  },

  checkCurrentUser({ commit }) {
    const auth = getAuth()

    if (auth) {
      commit('SET_UID', auth.currentUser.uid)
    } else {
      commit('SET_UID', null)
    }
  }
}

export default actions
