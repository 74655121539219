<template>

  <component :is="layout"/>

</template>

<script>
import { mapState } from 'vuex'
import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'
import AdminLayout from '@/layouts/AdminLayout'

export default {
  name: 'BWS',

  components: {
    MainLayout,
    EmptyLayout,
    AdminLayout
  },

  computed: {
    ...mapState({
      msg: state => state.msg.msg,
    }),

    layout() {
      return ( this.$route.meta.layout || 'empty' ) + '-layout'
    }
  },

  watch: {
    msg: {
      handler(val) {
        if (val) {
          if (val?.error) {
            this.$toast.show(val.error.message, {
              type: 'error',
              position: 'top-right'
            })
            return
          }

          if (val?.type === 'success') {
            this.$toast.show(val.message, {
              type: 'success',
              position: 'top-right'
            })
            return
          }

          if (val?.type === 'error') {
            this.$toast.show(val.message, {
              type: 'error',
              position: 'top-right'
            })
            return
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
