import { createStore } from 'vuex'

import { msgModule } from '@/store/modules/msg/index'
import { authModule } from '@/store/modules/auth/index'
import { eventsModule } from '@/store/modules/events/index'
import { userEventsModule } from '@/store/modules/user-events/index'
import { imagesModule } from '@/store/modules/images/index'
import { tableModule } from '@/store/modules/table/index'
import { commonModule } from '@/store/modules/common/index'
import { memoModule } from '@/store/modules/memo/index'
import { viewsModule } from '@/store/modules/views/index'

export default createStore({
  modules: {
    msg: msgModule,
    auth: authModule,
    events: eventsModule,
    images: imagesModule,
    table: tableModule,
    common: commonModule,
    memo: memoModule,
    userEvents: userEventsModule,
    views: viewsModule
  }
})
