const mutations = {
  SET_UID(state, val) {
    state.uid = val
  },

  SET_AUTH_IS_LOADING(state, val) {
    state.authIsLoading = val
  }
}

export default mutations
