import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: 'AIzaSyCgZGkOHWqd04fcVyH7aflUNGkcQRYeqP8',
    authDomain: 'bukvi-c2a38.firebaseapp.com',
    projectId: 'bukvi-c2a38',
    storageBucket: 'bukvi-c2a38.appspot.com',
    messagingSenderId: '7933827577',
    appId: '1:7933827577:web:402e39a7db5bcd412d0528'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const storage = getStorage(app)

export {
    db,
    storage
}