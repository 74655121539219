const mutations = {
  SET_TABLE(state, val) {
    state.table = val
  },

  SET_TABLE_IS_LOADING(state, val) {
    state.tableIsLoading = val
  }
}

export default mutations
