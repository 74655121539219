<template>
  <div class="admin-layout">

    <BWSAdminNav />

    <div class="container-fluid">
      <div class="row h-100">
        <BWSAdminSidebar />

        <router-view />
      </div>
    </div>

    <BWSAdminFooter />
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BWSAdminNav from '@/components/admin/BWSAdminNav.vue'
import BWSAdminFooter from '@/components/admin/BWSAdminFooter.vue'
import BWSAdminSidebar from '@/components/admin/BWSAdminSidebar.vue'

export default {
  name: 'AdminLayout',

  components: {
    BWSAdminNav,
    BWSAdminFooter,
    BWSAdminSidebar
  },


  methods: {
    ...mapActions([
      'checkCurrentUser',
      'fetchCommon'
    ])
  },

  async created() {
    const resp = await this.fetchCommon()
    document.title = resp.pageTitle
  },

  mounted() {
    this.checkCurrentUser()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/layouts/admin-layout.scss';
</style>
