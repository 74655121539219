import state from '@/store/modules/common/state'
import mutations from '@/store/modules/common/mutations'
import actions from '@/store/modules/common/actions'

const commonModule = {
    state,
    mutations,
    actions
}

export { commonModule }
