import state from '@/store/modules/auth/state'
import mutations from '@/store/modules/auth/mutations'
import actions from '@/store/modules/auth/actions'

const authModule = {
    state,
    mutations,
    actions
}

export { authModule }
