import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { 
  PAGE_USER_EVENT_LIST,
  PAGE_USER_EVENT_DETAIL,
  PAGE_USER_TABLE,
  PAGE_AUTH,
  PAGE_ADMIN_EVENT_LIST,
  PAGE_ADMIN_EVENT_DETAIL,
  PAGE_ADMIN_EVENT_CREATE,
  PAGE_ADMIN_EVENT_UPDATE,
  PAGE_ADMIN_TABLE,
  PAGE_ADMIN_COMMON,
  PAGE_ADMIN_REMIND,
  PAGE_ADMIN_VIEWS_LIST,
  PAGE_ERROR
} from '@/constants/router'

const routes = [
  {
    path: '/',
    name: PAGE_USER_EVENT_LIST,
    component: () => import('@/pages/user/UserEventListPage.vue'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/event/:id',
    name: PAGE_USER_EVENT_DETAIL,
    component: () => import('@/pages/user/UserEventDetailPage.vue'),
    props: true,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/table',
    name: PAGE_USER_TABLE,
    component: () => import('@/pages/user/UserTablePage.vue'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/auth',
    name: PAGE_AUTH,
    component: () => import('@/pages/AuthPage.vue'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/admin',
    name: PAGE_ADMIN_EVENT_LIST,
    component: () => import('@/pages/admin/AdminEventListPage.vue'),
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: '/admin/:id',
    name: PAGE_ADMIN_EVENT_DETAIL,
    component: () => import('@/pages/admin/AdminEventDetailPage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: '/admin/create',
    name: PAGE_ADMIN_EVENT_CREATE,
    component: () => import('@/pages/admin/AdminEventCreatePage.vue'),
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: '/admin/update/:id',
    name: PAGE_ADMIN_EVENT_UPDATE,
    component: () => import('@/pages/admin/AdminEventUpdatePage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: '/admin/schedule',
    name: PAGE_ADMIN_TABLE,
    component: () => import('@/pages/admin/AdminTablePage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },

  {
    path: '/admin/common',
    name: PAGE_ADMIN_COMMON,
    component: () => import('@/pages/admin/AdminCommonPage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },

  {
    path: '/admin/remind',
    name: PAGE_ADMIN_REMIND,
    component: () => import('@/pages/admin/AdminRemindPage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: '/admin/views',
    name: PAGE_ADMIN_VIEWS_LIST,
    component: () => import('@/pages/admin/AdminViewsListPage.vue'),
    props: true,
    meta: {
      layout: 'admin',
      auth: true
    }
  },
  {
    path: "/:catchAll(.*)",
    name: PAGE_ERROR,
    component: () => import('@/pages/ErrorPage.vue'),
    meta: {
      layout: 'empty'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    let removeListener = onAuthStateChanged(
      getAuth(), 
        (user) => {
          removeListener()
          resolve(user)
      },
      reject
    )
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (await getCurrentUser()) {
      next()
    } else {
      next('/auth')
    }
  } else {
    next()
  }
})

export default router
