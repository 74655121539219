<template>
  <div class="empty-layout">

    <router-view />
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EmptyLayout',

  methods: {
    ...mapActions([
      'fetchCommon'
    ])
  },

  async created() {
    const resp = await this.fetchCommon()
    document.title = resp.pageTitle
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/layouts/empty-layout.scss';
</style>
