import state from '@/store/modules/table/state'
import mutations from '@/store/modules/table/mutations'
import actions from '@/store/modules/table/actions'

const tableModule = {
    state,
    mutations,
    actions
}

export { tableModule }
