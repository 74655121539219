import state from '@/store/modules/events/state'
import getters from '@/store/modules/events/getters'
import mutations from '@/store/modules/events/mutations'
import actions from '@/store/modules/events/actions'

const eventsModule = {
  state,
  getters,
  mutations,
  actions
}

export { eventsModule }
