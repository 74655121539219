const state = () => {
  return {
    userEvents: [],
    userEventsLimit: 10,
    userEventsIsLoading: false,
    userEventsStartQuery: {},
    userEventsEndQuery: {},
    userCount: 0,

    userEvent: {},
    userEventIsLoading: false
  }
}

export default state
