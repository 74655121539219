<template>
  <nav class="navbar navbar-dark sticky-top bg-secondary p-0 shadow">

    <h6 class="navbar-brand mr-0 px-3">Панель администратора</h6>

    <div class="navbar-nav px-3">
      <button
        class="btn btn-sm btn-outline-dark"
        @click="onLogout"
      >
        Выйти
      </button>
    </div>
    
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PAGE_USER_EVENT_LIST } from '@/constants/router'

export default {
  name: 'BWSAdminNav',

  data: () => ({
    PAGE_USER_EVENT_LIST
  }),

  computed: {
    ...mapState({
      uid: state => state.auth.uid
    }),
  },

  methods: {
    ...mapActions([
      'logout'
    ]),

    async onLogout() {
      await this.logout()
    }
  },

  watch: {
    uid(val) {
      if(val === null) {
        this.$router.push({ name: PAGE_USER_EVENT_LIST })
      }
    }
  }
}
</script>
