const state = () => {
  return {
    events: [],
    eventsLimit: 10,
    eventsIsLoading: false,
    startQueries: [],
    endQueries: [],
    counter: 0,
    count: 0,

    event: {},
    eventIsLoading: false
  }
}

export default state
