import { db } from '@/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"

const actions = {
  async createImages({ commit }, { id, images }) {
    try {
      const storage = getStorage()

      const imgPromises = []

      images.forEach((el) => {
        const storageRef = ref(storage, `${id}/${el.name}`)
        let promise = uploadBytes(storageRef, el)
        imgPromises.push(promise)
      })

      const snapshots = await Promise.all(imgPromises)

      const urlPromises = []
      snapshots.forEach((el) => {
        urlPromises.push(getDownloadURL(el.ref))
      })

      const urls = await Promise.all(urlPromises)

      return urls
      
    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    }
  },

  async addImage({ commit }, { id, img, file }) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      const storage = getStorage()
      const storageRef = ref(storage, `${id}/${file.name}`)
      const snapshot = await uploadBytes(storageRef, file)

      const url = await getDownloadURL(snapshot.ref)
      img.push(url)

      const docRef = doc(db, 'news', id)
      await updateDoc(docRef, { img })

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async removeImage({ commit }, { id, name, img }) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      const storage = getStorage()
      const fileRef = ref(storage, `${id}/${name}`)
      const storePromise = deleteObject(fileRef)

      const docRef = doc(db, 'news', id)
      const docPromise = updateDoc(docRef, {
        img
      })

      await Promise.all([docPromise, storePromise])

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async removeImages({ commit }, { id, images }) {
    try {
      const storage = getStorage()

      const promises = []

      images.forEach(el => {
        const imgRef = ref(storage, `${id}/${el.name}`)
        const promise = deleteObject(imgRef)
        promises.push(promise)
      })

      await Promise.all(promises)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    }
  }
}

export default actions
