export const PAGE_USER_EVENT_LIST = 'userEventListPage'

export const PAGE_USER_EVENT_DETAIL = 'userEventDetailPage'

export const PAGE_USER_TABLE = 'userTablePage'

export const PAGE_AUTH = 'authPage'

export const PAGE_ADMIN_EVENT_LIST = 'adminEventListPage'

export const PAGE_ADMIN_EVENT_DETAIL = 'adminEventDetailPage'

export const PAGE_ADMIN_EVENT_CREATE = 'adminEventCreatePage'

export const PAGE_ADMIN_EVENT_UPDATE = 'adminEventUpdatePage'

export const PAGE_ADMIN_TABLE = 'adminTablePage'

export const PAGE_ADMIN_COMMON = 'adminCommonPage'

export const PAGE_ADMIN_REMIND = 'adminRemindPage'

export const PAGE_ADMIN_VIEWS_LIST = 'adminViewsListPage'

export const PAGE_ERROR = 'ErrorPage'
