<template>
  <nav
    class="navbar navbar-expand-lg navbar-light user-nav sticky-top shadow-sm"
    :class="{ 'user-nav--loading' : commonIsLoading && !common.id }"
  >
    <div class="container user-nav__container" v-if="common.id">

      <div class="user-nav__link-wrapper">
        
        <div class="user-nav__title">
          {{ common.classTitle }}
        </div>
      </div>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse user-nav__collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              :to="{ name: PAGE_USER_EVENT_LIST }"
              class="nav-link user-nav__btn"
            >
              Главная
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              :to="{ name: PAGE_USER_TABLE }"
              class="nav-link user-nav__btn user-nav__btn--last"
            >
              Расписание
            </router-link>
          </li>
        </ul>

        <div class="user-nav__auth">
          <router-link
            :to="{ name: PAGE_AUTH }"
            class="nav-link user-nav__btn user-nav__btn--last"
          >
            Войти
          </router-link>
        </div>
      </div>
      
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import {
  PAGE_USER_EVENT_LIST,
  PAGE_USER_TABLE,
  PAGE_AUTH
} from '@/constants/router'

export default {
  name: 'BWSUserNav',

  data: () => ({
    PAGE_USER_EVENT_LIST,
    PAGE_USER_TABLE,
    PAGE_AUTH
  }),

  computed: {
    ...mapState({
      common: state => state.common.common,
      commonIsLoading: state => state.common.commonIsLoading
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/user/user-nav.scss';
</style>
