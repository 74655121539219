const mutations = {
  SET_EVENTS(state, val) {
    state.events.length = 0
    state.events = val
  },

  SET_EVENTS_IS_LOADING(state, val) {
    state.eventsIsLoading = val
  },

  SET_START_QUERY(state, val) {
    state.startQueries.push(val)
  },

  SET_END_QUERY(state, val) {
    state.endQueries.push(val)
  },

  SET_COUNTER(state, val) {
    state.counter = val
  },

  SET_COUNT(state, val) {
    state.count = val
  },

  SET_EVENT(state, val) {
    state.event = val
  },

  SET_EVENT_IS_LOADING(state, val) {
    state.eventIsLoading = val
  }
}

export default mutations
