import state from '@/store/modules/user-events/state'
import mutations from '@/store/modules/user-events/mutations'
import actions from '@/store/modules/user-events/actions'

const userEventsModule = {
  state,
  mutations,
  actions
}

export { userEventsModule }
