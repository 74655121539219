import state from '@/store/modules/memo/state'
import mutations from '@/store/modules/memo/mutations'
import actions from '@/store/modules/memo/actions'

const memoModule = {
    state,
    mutations,
    actions
}

export { memoModule }
