import { db } from '@/firebase'
import { query, collection, getCountFromServer, orderBy, limit, getDocs, startAfter, startAt, doc, getDoc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async fetchEvents({ state, commit }) {
    try {
      commit('SET_EVENTS_IS_LOADING', true)

      const coll = collection(db, 'news')
      const snapshot = await getCountFromServer(coll)
      commit('SET_COUNT', snapshot.data().count)

      const fetchQuery = query(collection(db, "news"), orderBy("pubDate", "desc"), limit(state.eventsLimit))
      const documentSnapshots = await getDocs(fetchQuery)
      const events = convertSnapshot(documentSnapshots)
      commit('SET_EVENTS', events)

      const startQuery = documentSnapshots.docs[0]
      commit('SET_START_QUERY', startQuery)

      const lastQuery = documentSnapshots.docs[documentSnapshots.docs.length-1]
      commit('SET_END_QUERY', lastQuery)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENTS_IS_LOADING', false)
    }
  },

  async fetchNextEvents({ state, commit }) {
    try {
      commit('SET_EVENTS_IS_LOADING', true)

      const fetchNextQuery = query(collection(db, "news"), orderBy("pubDate", "desc"), startAfter(state.endQueries[state.counter]), limit(state.eventsLimit))
      const documentSnapshots = await getDocs(fetchNextQuery)
      const events = convertSnapshot(documentSnapshots)
      commit('SET_EVENTS', events)

      const startQuery = documentSnapshots.docs[0]
      commit('SET_START_QUERY', startQuery)

      const lastQuery = documentSnapshots.docs[documentSnapshots.docs.length-1]
      commit('SET_END_QUERY', lastQuery)

      let counter = state.counter + 1
      commit('SET_COUNTER', counter)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENTS_IS_LOADING', false)
    }
  },

  async fetchPrevEvents({ state, commit }) {
    try {
      commit('SET_EVENTS_IS_LOADING', true)

      const fetchPrevQuery = query(collection(db, "news"), orderBy("pubDate", "desc"), startAt(state.startQueries[state.counter - 1]), limit(state.eventsLimit))
      const documentSnapshots = await getDocs(fetchPrevQuery)
      const events = convertSnapshot(documentSnapshots)
      commit('SET_EVENTS', events)

      let counter = state.counter - 1
      commit('SET_COUNTER', counter)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENTS_IS_LOADING', false)
    }
  },

  async fetchEvent({ commit }, id) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      const docRef = doc(db, 'news', id)
      const docSnap = await getDoc(docRef)
      const event = docSnap.data()

      const fullImages = []

      event.img.forEach(el => {
        let arr = el.split('%2F')
        arr = arr[1].split('?')
        const name = arr[0]

        fullImages.push({
          name,
          fullPath: el
        })
      })

      event.images = fullImages

      commit('SET_EVENT', event)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async createEvent({ commit, dispatch }, { event, images }) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      const docRef = await addDoc(collection(db, 'news'), event)
      const id = docRef.id

      const urls = await dispatch('createImages', { id, images })

      await updateDoc(doc(db, "news", id), {
        img: urls
      })

      return id

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async updateEvent({ commit }, { id, title, text }) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      const docRef = doc(db, 'news', id)
      await updateDoc(docRef, { title, text })

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async removeEvent({ commit, dispatch }, { id, images }) {
    try {
      commit('SET_EVENT_IS_LOADING', true)

      await dispatch('removeImages', { id, images })
      await deleteDoc(doc(db, "news", id))

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  } 
}

export default actions
