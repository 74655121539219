const mutations = {
  SET_VIEWS(state, val) {
    state.views.length = 0
    state.views = val
  },

  SET_VIEWS_IS_LOADING(state, val) {
    state.viewsIsLoading = val
  }
}

export default mutations
