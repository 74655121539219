import { db } from '@/firebase'
import { query, collection, orderBy, getDocs, doc, updateDoc } from 'firebase/firestore'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async fetchTable({ commit }) {
    try {
      commit('SET_TABLE_IS_LOADING', true)

      const fetchQuery = query(collection(db, 'table'), orderBy('innerId', 'asc'))
      const documentSnapshots = await getDocs(fetchQuery)

      const table = convertSnapshot(documentSnapshots)

      commit('SET_TABLE', table)

      return table

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_TABLE_IS_LOADING', false)
    }
  },

  async updateTable({ commit }, table) {
    try {
      commit('SET_TABLE_IS_LOADING', true)

      const promises = []

      table.forEach(el => {
        const docRef = doc(db, 'table', el.id)
        const promise = updateDoc(docRef, { items: el.items })
        promises.push(promise)
      })

      await Promise.all(promises)

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_TABLE_IS_LOADING', false)
    }
  }
}

export default actions
