<template>
  <div class="main-layout">

    <BWSUserNav />

    <div class="container">
      <div class="row h-100">

        <router-view />
        
      </div>
    </div>

    <BWSUserFooter />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BWSUserNav from '@/components/user/BWSUserNav.vue'
import BWSUserFooter from '@/components/user/BWSUserFooter.vue'

export default {
  name: 'MainLayout',

  components: {
    BWSUserNav,
    BWSUserFooter
  },

  data: () => ({
    data: {}
  }),

  computed: {
    ...mapState({
      common: state => state.common.common
    })
  },

  methods: {
    ...mapActions([
      'fetchCommon'
    ])
  },

  async created() {
    this.data = await this.fetchCommon()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/layouts/main-layout.scss';
</style>
