const mutations = {
  SET_USER_EVENTS(state, val) {
    state.userEvents.length = 0
    state.userEvents = val
  },

  SET_USER_EVENTS_IS_LOADING(state, val) {
    state.userEventsIsLoading = val
  },

  SET_USER_EVENTS_START_QUERY(state, val) {
    state.userEventsStartQuery = val
  },

  SET_USER_EVENTS_END_QUERY(state, val) {
    state.userEventsEndQuery = val
  },

  SET_USER_EVENT(state, val) {
    state.userEvent = val
  },

  SET_USER_EVENT_IS_LOADING(state, val) {
    state.userEventIsLoading = val
  },

  SET_USER_COUNT(state, val) {
    state.userCount = val
  }
}

export default mutations
