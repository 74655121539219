<template>
  <footer
    class="user-footer"
    :class="{ 'user-footer--loading' : commonIsLoading && !common.id }"
  >
    <div class="container" v-if="common.id">
      <div class="row h-100">
        
        <div class="col-12 col-lg-3 d-flex flex-column justify-content-between">
          <div class="user-footer__title">
            {{ common.classTitle }}
          </div>

          <div class="user-footer__creds">
            <i>by go11ga &copy;</i>
          </div>
        </div>

        <div class="col-12 col-lg-5">
          <div class="user-footer__text">
            Классный руководитель: {{ common.teacherName }}
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <div class="user-footer__text">
            {{ common.fullSchoolTitle }}
          </div>

          <div class="user-footer__text">
            {{ common.schoolAddress }}
          </div>

          <a class="user-footer__link" href="https://sch1354uz.mskobr.ru/" target="_blank">
            Сайт школы
          </a>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BWSUserFooter',

  computed: {
    ...mapState({
      common: state => state.common.common,
      commonIsLoading: state => state.common.commonIsLoading
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/user/user-footer.scss';
</style>
