<template>
	<nav class="col-2 bg-light sidebar">
		<div class="position-sticky py-2">
			<ul class="nav flex-column">

				<li class="nav-item">
					<router-link
						class="nav-link d-flex justify-content-start align-items-center"
						:to="{ name: PAGE_ADMIN_EVENT_LIST }"
					>
						<span class="d-block me-2">
							<i class="bi bi-file-earmark-text"></i>
						</span>
						<span class="d-block">
							Список событий
						</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link
						class="nav-link d-flex justify-content-start align-items-center"
						:to="{ name: PAGE_ADMIN_VIEWS_LIST }"
					>
						<span class="d-block me-2">
							<i class="bi bi-bar-chart-fill"></i>
						</span>
						<span class="d-block">
							Статистика просмотров
						</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link
						class="nav-link d-flex justify-content-start align-items-center"
						:to="{ name: PAGE_ADMIN_TABLE }"
					>
						<span class="d-block me-2">
							<i class="bi bi-layout-text-sidebar-reverse"></i>
						</span>
						<span class="d-block">
							Расписание
						</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link
						class="nav-link d-flex justify-content-start align-items-center"
						:to="{ name: PAGE_ADMIN_COMMON }"
					>
						<span class="d-block me-2">
							<i class="bi bi-collection"></i>
						</span>
						<span class="d-block">
							Общая информация
						</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link
						class="nav-link d-flex justify-content-start align-items-center"
						:to="{ name: PAGE_ADMIN_REMIND }"
					>
						<span class="d-block me-2">
							<i class="bi bi-lightbulb"></i>
						</span>
						<span class="d-block">
							Памятка
						</span>
					</router-link>
				</li>

			</ul>
		</div>
	</nav>
</template>

<script>
import {
	PAGE_ADMIN_EVENT_LIST,
	PAGE_ADMIN_VIEWS_LIST,
	PAGE_ADMIN_TABLE,
	PAGE_ADMIN_COMMON,
	PAGE_ADMIN_REMIND
} from '@/constants/router'

export default {
  name: 'BWSAdminSidebar',

  data: () => ({
  	PAGE_ADMIN_EVENT_LIST,
  	PAGE_ADMIN_VIEWS_LIST,
  	PAGE_ADMIN_TABLE,
  	PAGE_ADMIN_COMMON,
  	PAGE_ADMIN_REMIND
  })
}
</script>

<style lang="scss">
@import '@/assets/scss/components/admin/admin-sidebar.scss';
</style>
