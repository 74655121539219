const mutations = {
  SET_MEMO(state, val) {
    state.memo = val
  },

  SET_MEMO_IS_LOADING(state, val) {
    state.memoIsLoading = val
  }
}

export default mutations
