const mutations = {
  SET_COMMON(state, val) {
    state.common = val
  },

  SET_COMMON_IS_LOADING(state, val) {
    state.commonIsLoading = val
  }
}

export default mutations
