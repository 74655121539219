import { db } from '@/firebase'
import { query, collection, getDocs, doc, updateDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async fetchCommon({ commit }) {
    try {
      commit('SET_COMMON_IS_LOADING', true)

      const fetchQuery = query(collection(db, 'common'))
      const documentSnapshots = await getDocs(fetchQuery)

      const common = convertSnapshot(documentSnapshots)
      const commonData = common[0]

      commit('SET_COMMON', commonData)

      return commonData

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_COMMON_IS_LOADING', false)
    }
  },

  async updateCommon({ commit }, { id, formData }) {
    try {
      commit('SET_COMMON_IS_LOADING', true)

      const docRef = doc(db, 'common', id)
      await updateDoc(docRef, { ...formData })

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_COMMON_IS_LOADING', false)
    }
  },

  async updateImg({ commit }, file) {
    try {
      commit('SET_COMMON_IS_LOADING', true)

      const storage = getStorage()
      const listRef = ref(storage, 'about')
      const res = await listAll(listRef)

      const fileName = res?.items[0]?.name

      if (fileName) {
        const storageRef = ref(storage, `about/${fileName}`)
        await deleteObject(storageRef)
      }

      const storageRef = ref(storage, `about/${file.name}`)
      const snapshot = await uploadBytes(storageRef, file)
      const url = await getDownloadURL(snapshot.ref)

      const docRef = doc(db, 'common', 'data')
      await updateDoc(docRef, { img: url })

      return url
     
    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_COMMON_IS_LOADING', false)
    }
  }
}

export default actions
