import { db } from '@/firebase'
import { query, collection, getCountFromServer, orderBy, limit, getDocs, startAfter, doc, getDoc, updateDoc, increment } from 'firebase/firestore'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async fetchUserEvents({ state, commit }) {
    try {
      commit('SET_USER_EVENTS_IS_LOADING', true)

      const coll = collection(db, 'news');
      const snapshot = await getCountFromServer(coll)
      commit('SET_USER_COUNT', snapshot.data().count)

      const fetchQuery = query(collection(db, 'news'), orderBy('pubDate', 'desc'), limit(state.userEventsLimit))
      const documentSnapshots = await getDocs(fetchQuery)

      const userEvents = convertSnapshot(documentSnapshots)
      commit('SET_USER_EVENTS', userEvents)

      const startQuery = documentSnapshots.docs[0]
      commit('SET_USER_EVENTS_START_QUERY', startQuery)

      const lastQuery = documentSnapshots.docs[documentSnapshots.docs.length-1]
      commit('SET_USER_EVENTS_END_QUERY', lastQuery)

      return userEvents

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_USER_EVENTS_IS_LOADING', false)
    }
  },

  async fetchNextUserEvents({ state, commit }) {
    try {
      commit('SET_USER_EVENTS_IS_LOADING', true)

      const fetchNextQuery = query(collection(db, 'news'), orderBy('pubDate', 'desc'), startAfter(state.userEventsEndQuery), limit(state.userEventsLimit))
      const documentSnapshots = await getDocs(fetchNextQuery)

      const userEvents = convertSnapshot(documentSnapshots)
      commit('SET_USER_EVENTS', userEvents)

      const lastQuery = documentSnapshots.docs[documentSnapshots.docs.length-1]
      commit('SET_USER_EVENTS_END_QUERY', lastQuery)

      return userEvents

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_USER_EVENTS_IS_LOADING', false)
    }
  },

  async fetchUserEvent({ commit }, id) {
    try {
      commit('SET_USER_EVENT_IS_LOADING', true)

      const docRef = doc(db, 'news', id)
      const docSnap = await getDoc(docRef)
      const event = docSnap.data()

      event.id = id
      commit('SET_USER_EVENT', event)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_USER_EVENT_IS_LOADING', false)
    }
  },

  async increaseUserEventWatch({ commit }, id) {
    try {
      const docRef = doc(db, "news", id)

      await updateDoc(docRef, {
        watch: increment(1)
      })
      
    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    }
  }
}

export default actions
