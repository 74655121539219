import { db } from '@/firebase'
import { query, collection, getDocs, doc, updateDoc } from 'firebase/firestore'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async fetchMemo({ commit }) {
    try {
      commit('SET_MEMO_IS_LOADING', true)

      const fetchQuery = query(collection(db, 'memo'))
      const documentSnapshots = await getDocs(fetchQuery)

      const memo = convertSnapshot(documentSnapshots)
      const memoData = memo[0]

      commit('SET_MEMO', memoData)

      return memoData

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_MEMO_IS_LOADING', false)
    }
  },

  async updateMemo({ commit }, { id, text }) {
    try {
      commit('SET_MEMO_IS_LOADING', true)

      const docRef = doc(db, 'memo', id)
      await updateDoc(docRef, { text })

      return true

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_MEMO_IS_LOADING', false)
    }
  }
}

export default actions
