import { db } from '@/firebase'
import { query, collection, addDoc, getDocs, orderBy } from 'firebase/firestore'
import convertSnapshot from '@/utils/convertSnapshot.js'

const actions = {
  async createViewItem({ commit }, id) {
    try {
      const viewItem = {
        eventId: id,
        date: new Date(),
        watch: 1
      }

      await addDoc(collection(db, 'views'), viewItem)

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_EVENT_IS_LOADING', false)
    }
  },

  async fetchViews({ commit }) {
    try {
      commit('SET_VIEWS_IS_LOADING', true)

      const fetchQuery = query(collection(db, 'views'), orderBy('date', 'asc'))
      const documentSnapshots = await getDocs(fetchQuery)

      const views = convertSnapshot(documentSnapshots)

      commit('SET_VIEWS', views)

      return views

    } catch(error) {
      commit('SET_MSG', { error, type: 'error' })
    } finally {
      commit('SET_VIEWS_IS_LOADING', false)
    }
  },
}

export default actions
