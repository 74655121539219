import state from '@/store/modules/views/state'
import mutations from '@/store/modules/views/mutations'
import actions from '@/store/modules/views/actions'

const viewsModule = {
  state,
  mutations,
  actions
}

export { viewsModule }
