<template>
	<footer class="footer mt-auto bg-secondary p-0">
		<div class="text-white px-3 py-2">
			<i>by go11ga &copy;</i>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'BWSAdminFooter'
}
</script>
